import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Container, Button, FormWithChildren, TextArea, TextInput, Alert, StripeMain} from 'library';
import SimpleNavbar from 'pages/custom_project_pages/buildingblocks/simplenavbar.js';
import SimpleFooter from 'pages/custom_project_pages/buildingblocks/simplefooter.js';
import Nav from 'projectLibrary/nav.js';
import Subscribe from 'projectLibrary/subscribe.js';
import Body from 'pages/custom_project_pages/buildingblocks/body.js';

class Research extends Component {


    render() {


        return (<div>

            <Nav />

            <Body>
            <div className="container" style={{paddingTop:'50px'}}>
                <h3>World From Scratch Research and Technology</h3>

                <p>One of the ways that World From Scratch contributes to its mission is by publishing all the technology and designs it develops available to anyone for however they want to utilize it.</p>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div class="card-body">
                                <h5 class="card-title">The Prototype House Designs</h5>
                                <p class="card-text">These are the architectural drawings that we used for our prototype house. The prototype house ended up being built for under ~25k, is 800 total square feet and has a greenhouse capable of producing hundreds of plants at once.</p>
                                <Button href='https://world-from-scratch.s3.us-east-2.amazonaws.com/Greenhouse+Foundation+Revision+Set+Stamped+%26+Signed+(2)+(6).pdf' text='Download' type='primary' />
                            </div>
                        </div>
                    </div>

                </div>

                <h3 style={{marginTop:'50px'}}>Help Us Move The Mission Forward With Your Ideas!</h3>
                <p>We have an online community with people just like you helping us and themselves push the world forward. Whether you are interested in giving us feedback on the website, you've got great taste in interior design, you've got a green thumb, just want to encourage us or you've got any other ways to help, please join us.</p>
                <Button type='dark' href='https://discord.gg/yWv3m2WQpP' text='Join Our Discord Community' />

                <div style={{height:'50px'}}></div>

            </div>
            </Body>

            <SimpleFooter />
        </div>);
    }
}

export default Research;
