import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Container, Button, FormWithChildren, TextArea, TextInput, Alert} from 'library';
import SimpleNavbar from 'pages/custom_project_pages/buildingblocks/simplenavbar.js';
import SimpleFooter from 'pages/custom_project_pages/buildingblocks/simplefooter.js';
import Nav from 'projectLibrary/nav.js';
import Body from 'pages/custom_project_pages/buildingblocks/body.js';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {submitted: false}

        this.submit = this.submit.bind(this);
    }

    submit(state, callback) {
        ajaxWrapper('POST','/api/email/', {text:state['text'] + '<br/> from ' + state['email'], subject:'Message From World From Scratch: ' + state['subject']}, callback())

        callback();
        this.setState({submitted: true})
    }

    render() {

        var submitted = null;
        if (this.state.submitted) {
            submitted = <Alert text="Sent! We'll get back to you as soon as we can!" type='success' />
        }

        return (<div>

            <Nav />
            <Body>
            <div className="container" style={{paddingTop:'50px'}}>
                <h3>Contact Us</h3>

                <FormWithChildren submit={this.submit} submit_text={'Send'}>
                    <TextInput name='email' label='Your Email' />
                    <TextInput name='subject' label='Subject' />
                    <TextArea name='text' label='Your Message' />
                </FormWithChildren>
                {submitted}
            </div>
            </Body>

            <SimpleFooter />
        </div>);
    }
}

export default Contact;
