import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, Container, Header, FormWithChildren, TextInput, Instance, Navbar, NavLink, Div, Image, Span, Link, Paragraph, PasswordInput} from 'library';
import SimpleNavbar from 'pages/custom_project_pages/buildingblocks/simplenavbar.js';
import SimpleFooter from 'pages/custom_project_pages/buildingblocks/simplefooter.js';


class EditUser extends Component {

    render() {

        return (<div>
			<SimpleNavbar />

			<Container order={"1"} style={{'padding-top': '20px'}} min_height={true} >
				<Instance dataUrl={"/api/user/user/{params.1}/"} objectName={"user"} >
					<Header text={"Edit User"}  />
					<FormWithChildren deleteUrl={resolveVariables({"text":"/api/user/user/{params.1}/delete/"}, window.cmState.getGlobalState(this))["text"]} functions={[]} submitUrl={resolveVariables({"text":"/api/user/user/{params.1}/"}, window.cmState.getGlobalState(this))["text"]} redirectUrl={"/dashboard/"} deleteRedirectUrl={"/logout/"} >
						<TextInput name={"email"} label={"Email Address"} default={resolveVariables({"text":"{props.email}"}, this)["text"]}  />
						<PasswordInput name={"password"} required={false} confirm_password={true}  />
					</FormWithChildren>
				</Instance>
			</Container>

			<SimpleFooter />
        </div>);
    }
}
export default EditUser;
