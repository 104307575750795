import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Container, Button, FormWithChildren, TextArea, TextInput, Alert, StripeMain, NumberInput} from 'library';
import SimpleNavbar from 'pages/custom_project_pages/buildingblocks/simplenavbar.js';
import SimpleFooter from 'pages/custom_project_pages/buildingblocks/simplefooter.js';
import Nav from 'projectLibrary/nav.js';
import Body from 'pages/custom_project_pages/buildingblocks/body.js';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {submitted: false, amount:0, email:'', message:''}

        this.submit = this.submit.bind(this);
        this.donate = this.donate.bind(this);
        this.donation = this.donation.bind(this);
        this.donation_callback = this.donation_callback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
    }

    setGlobalState(name, state) {
        if (state['amount']) {
            state['amount'] = parseFloat(state['amount'])
        }
        this.setState(state);
    }

    submit(state, callback) {
        ajaxWrapper('POST','/api/email/', {text:"Someone started donating __/mon for world from scratch!", subject:'New Donation World From Scratch'}, callback())

        callback();
        this.setState({submitted: true})
    }

    donate(amount) {
        this.setState({amount: amount})
    }

    donation(value){
        console.log(value);

        var url = '/api/home/StripeDonation/';
        var data = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            amount: this.state.price,
            charge: value['charge']
        };

        if (this.props.video_id){
            data['video'] = this.props.video_id;
        }

        ajaxWrapper('POST', url, data, this.donation_callback);
    }

    donation_callback(value){
        this.setState({message: 'Thank you for your donation!'});
    }

    render() {
        var api_key = 'pk_live_51HxLfWFVcNyHzweOdyy6VwQ9xrIGHZbwjoTzrrO7yZRs0BxDP5UfibzA3gBoRNVq1iNE1fF2eziiqUjIlkamU5Rf0083NeIGWK';

        var collect_payment = [
            <div style={{marginTop:'30px'}}>
                <h2>I want to give a one-time donation.</h2>
                <FormWithChildren setGlobalState={this.setGlobalState} autoSetGlobalState={true} global_state_name={'donation'}>
                    <NumberInput name='amount' label='How much would you like to give to support us?' />
                </FormWithChildren>
            </div>,
            <div>
                <p>Want to get in contact before donating or don't see an option you like? <a href='/contact/'>Contact Us Here</a></p>
            </div>
        ];

        if (typeof(this.state.amount) == 'number' && this.state.amount > 0) {
            collect_payment = <div style={{marginTop:'30px'}}>
            <h2>Just enter your credit card and email below and donate ${this.state.amount} one time only. <br/>We'll make sure it changes the world.</h2>

            <p>We need your email address in order to send you the info the IRS needs to acknowledge your tax deductible donation.</p>
                <FormWithChildren setGlobalState={this.setGlobalState} autoSetGlobalState={true} global_state_name={'donation'}>
                    <TextInput name='email' label='Email Address' />
                </FormWithChildren>

                <StripeMain url={"/stripe/create_charge/"} callback={this.donation_callback} email={this.state.email} price={this.state.amount} submit={"Create A Better World"} description={"World From Scratch Donation. Thank you!"} api_key={api_key}/>
            </div>
        }
        else if (typeof(this.state.amount) == 'string') {
            var plan_to_payment_dict = {
                'price_1JrX7WFVcNyHzweOXLvikxPK': 10,
                'price_1JrX7WFVcNyHzweOAysgsG2i': 20,
                'price_1JrX7WFVcNyHzweOZGyHcRsJ': 50,
            }

            collect_payment = <div style={{marginTop:'30px'}}>
                <h2>Just enter your credit card and email below and start donating ${plan_to_payment_dict[this.state.amount]} per month. <br/>We'll make sure it changes the world.</h2>

                <p>We need your email address in order to send you the info the IRS needs to acknowledge your tax deductible donation.</p>
                <FormWithChildren setGlobalState={this.setGlobalState} autoSetGlobalState={true} global_state_name={'donation'}>
                    <TextInput name='email' label='Email Address' />
                </FormWithChildren>

                <StripeMain url={"/stripe/create_subscription/"} callback={this.donation_callback} email={this.state.email} price={this.state.amount} submit={"Create A Better World"} description={"World From Scratch Donation. Thank you!"} api_key={api_key}/>
            </div>
        }

        if (this.state.message != '') {
            collect_payment = <Alert text={this.state.message} type='success' />
        }


        return (<div>

            <Nav />

            <Body>
            <div className="container" style={{paddingTop:'50px'}}>
                <h1>Donate To Create A World Where Everyone Can Follow Their Dreams</h1>

                <p>Currently, all proceeds our going to our next goal to build the first neighborhood. We will be moving past the first prototype and building 8-10 1500 sq. ft. houses with a large greenhouse capable of feeding everyone living in the neighborhood as well as being able to share with local food banks and homeless shelters. The purpose of the neighborhood is to do a few things.</p>

                <ol>
                    <li>To refine the home design at the size of a single family home while still keeping costs low enough so that almost anyone could build their own and be able to live with total freedom.</li>
                    <li>To build partnerships and a group of volunteers that will help us build additional neighborhoods for people with low income and people without a home around the world.</li>
                    <li>To push our greenhouse technology forward and make it easy for anyone to be able to grow their own food from wherever they live.</li>
                </ol>

                <p>In addition, we are currently awaiting our application to become a 501(c)(3) nonprofit corporation. If successful, your donation will be tax deductible and we will notify you by email. In addition, any and all use of donations will be determined by World From Scratch in accordance to its <a href='/about/'>mission.</a></p>

                <h2 style={{marginTop:'30px'}}>Donate Monthly</h2>
                <div className="col-12 row">
                    <div className="col-md-4 col-sm-12">
                        <div className="card">
                            <div class="card-body">
                                <h5 class="card-title">$10/mon</h5>
                                <p class="card-text">Give just $10/mon and make a real difference!</p>
                                <Button onClick={() => this.donate('price_1JrX7WFVcNyHzweOXLvikxPK')} type="primary" text='Select' />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                        <div className="card">
                            <div class="card-body">
                                <h5 class="card-title">$20/mon</h5>
                                <p class="card-text">Give just $20/mon and change the world!</p>
                                <Button onClick={() => this.donate('price_1JrX7WFVcNyHzweOAysgsG2i')} type="primary" text='Select' />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                        <div className="card">
                            <div class="card-body">
                                <h5 class="card-title">$50/mon</h5>
                                <p class="card-text">Give just $50/mon and really dial up our progress!</p>
                                <Button onClick={() => this.donate('price_1JrX7WFVcNyHzweOZGyHcRsJ')} type="success" text='Select' />
                            </div>
                        </div>
                    </div>
                </div>

                <br/>

                {collect_payment}

                <br/><br/><br/><br/><br/><br/>
            </div>
            </Body>


            <SimpleFooter />
        </div>);
    }
}

export default Contact;
