import Moment from 'react-moment';
var moment = require('moment-timezone');


function format_date(date, date_format, timezone) {
    var date_dict = {};
    var tzaware_date = moment(date).tz(timezone);

    date_dict['dd'] = tzaware_date.format('DD');

    date_dict['mm'] = tzaware_date.format('MM');

    date_dict['yyyy'] = tzaware_date.format('YYYY');
    date_dict['yy'] = tzaware_date.format('YY');

    date_dict['HH'] = tzaware_date.format('HH');
    date_dict['hh'] = date_dict['HH'];
    date_dict['A'] = 'AM';
    if (date_dict['HH'] > 12){
        date_dict['hh'] = date_dict['hh'] - 12;
        date_dict['A'] = 'PM';
    }

    date_dict['MM'] = tzaware_date.format('mm');
    date_dict['SSS'] = tzaware_date.format('SSS');
    date_dict['SS'] = tzaware_date.format('SS');

    date_dict['ZZ'] = tzaware_date.format('zz');

    date_dict['LLLL'] = tzaware_date.format('LLLL');

    var keys = ['dd','mm','yyyy','yy','HH','hh','MM','SSS','SS', 'P', 'ZZ', 'A', 'LLLL'];
    for (var index in keys) {
        var key = keys[index];
        if (date_format){
            date_format = date_format.replace(key, date_dict[key]);
        }
    }

    return date_format;
}


export default format_date;
