import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, ListWithChildren, Container, Header, Button, Table, TableRow, TableHeader, TableData, Navbar, NavLink, If, Div, Image, Span, Link, Paragraph} from 'library';
import SimpleNavbar from 'pages/custom_project_pages/buildingblocks/simplenavbar.js';
import SimpleFooter from 'pages/custom_project_pages/buildingblocks/simplefooter.js';


class UserList extends Component {
    render() {

        return (<div>
			<SimpleNavbar />

			<Container order={"2"} min_height={true} >
				<Header text={"All Users"}  />
				<Button href={"/adminedituser/"} text={"Add New User"} type={"success"} style={{'margin-bottom': '20px'}}  />
				<Table >
					<TableRow >
						<TableHeader data={"Email"}  />
						<TableHeader data={"Is Staff?"}  />
						<TableHeader data={"Edit"}  />
					</TableRow>
					<ListWithChildren dataUrl={"/api/user/user/"} nowrapper={true} objectName={"user"} noDataMessage={"No Users Found"} >
						<TableRow >
							<TableData data={resolveVariables({"text":"{props.email}"}, this)["text"]}  />
							<TableData data={resolveVariables({"text":"{props.is_staff}"}, this)["text"]}  />
							<TableData >
								<Button href={resolveVariables({"text":"/adminEditUser/{props.id}/"}, this)["text"]} text={"Edit"} type={"primary"}  />
							</TableData>
						</TableRow>
					</ListWithChildren>
				</Table>
			</Container>

			<SimpleFooter />
        </div>);
    }
}
export default UserList;
