import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {TextInput, CSSInput} from 'library';

//Example
//var questionName = 'Question: ' + this.state.question.name;
//var questionText = {'text': this.state.question.text}
//var responseName = 'Response ' + this.state.answer.id;
//var responseText = {'text':this.state.answer.response}
//<Accordion names={[questionName, responseName]} open={[true,true]} ComponentList={[Paragraph, Paragraph]} ComponentProps={[questionText, responseText]} multiple={true} />


class Accordion extends React.Component {
    static component_name = 'Accordion';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'Name'} name={'name'} />,
                <TextInput label={'Index'} name={'index'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
            can_have_children: true,
        }
    }

    render() {
        var name = this.props.name;
        var index = this.props.index;

        var dataParent = '#accordion-' + index;
        if (this.props.multiple == true) {
            dataParent = '#accordion-' + index;
        }

        var collapsed = 'collapsed collapse';
        if (this.props.open == true) {
            collapsed += ' show';
        }

        var content = <div>
            <div className="card-header" id={"heading" + index}>
                <h4 className="mb-0" style={{textAlign:'left'}}>
                    <button className={'btn btn-link collapsed'} type="button" data-toggle="collapse" data-target={"#collapse" + index} aria-expanded="true" aria-controls={"collapse" + index}>
                        {name}
                    </button>
                </h4>
            </div>
            <div className="">
                <div id={"collapse" + index} className={collapsed} aria-labelledby={"heading" + index} data-parent={dataParent}>
                    <div className="card-body">
                        {this.props.children}
                    </div>
                </div>
            </div>
        </div>;

        return (
            <div key={index} className={"accordion " + this.props.className} style={this.props.style} id="accordion">
                {content}
            </div>
        );
    }
}

export default Accordion;
