import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, Container, If, Header, FormWithChildren, TextInput, Select, Instance, Navbar, NavLink, Div, Image, Span, Link, Paragraph} from 'library';
import SimpleNavbar from 'pages/custom_project_pages/buildingblocks/simplenavbar.js';
import SimpleFooter from 'pages/custom_project_pages/buildingblocks/simplefooter.js';


class AdminEditUser extends Component {

    render() {

        return (<div>
			<SimpleNavbar />

			<Container order={"1"} style={{'padding-top': '20px'}} min_height={true} >
				<If override={false} anti_logic={[['exists', '{params.1}']]} >
					<Header text={"Add New User"}  />
					<FormWithChildren defaults={{'password': ''}} functions={resolveVariables({"text":[['ajaxWrapper', {'url': '/api/email/', 'data': {'text':"You've been invited to a new app! Set up your password <a href='{location.origin}/passwordReset/{id}/'>here.</a>", 'subject': "You've been invited to a new app!", 'to_email': '{email}'}, 'type': 'POST'}], ['redirect', '/users/']]}, window.cmState.getGlobalState(this))["text"]} submitUrl={"/api/user/user/"} objectName={"user"} >
						<TextInput name={"email"} label={"Email Address"}  />
						<Select name={"is_staff"} label={"Is Staff?"} boolean={true} required={true}  />
					</FormWithChildren>
				</If>
				<If logic={[['exists', '{params.1}']]} override={false} >
					<Instance dataUrl={"/api/user/user/{params.1}/"} objectName={"user"} >
						<Header text={"Edit User"}  />
						<FormWithChildren deleteUrl={resolveVariables({"text":"/api/user/user/{params.1}/delete/"}, window.cmState.getGlobalState(this))["text"]} functions={[]} submitUrl={resolveVariables({"text":"/api/user/user/{params.1}/"}, window.cmState.getGlobalState(this))["text"]} redirectUrl={"/users/"} deleteRedirectUrl={"/users/"} >
							<TextInput name={"email"} label={"Email Address"} default={resolveVariables({"text":"{props.email}"}, this)["text"]}  />
							<Select name={"is_staff"} label={"Is Staff?"} boolean={true} required={false} defaultoption={resolveVariables({"text":"{props.is_staff}"}, this)["text"]}  />
						</FormWithChildren>
					</Instance>
				</If>
			</Container>

			<SimpleFooter />
        </div>);
    }
}
export default AdminEditUser;
