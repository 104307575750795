import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Container, Button, FormWithChildren, TextArea, TextInput, Alert, StripeMain} from 'library';
import SimpleNavbar from 'pages/custom_project_pages/buildingblocks/simplenavbar.js';
import SimpleFooter from 'pages/custom_project_pages/buildingblocks/simplefooter.js';
import Nav from 'projectLibrary/nav.js';
import Subscribe from 'projectLibrary/subscribe.js';
import Body from 'pages/custom_project_pages/buildingblocks/body.js';

class Research extends Component {


    render() {


        return (<div>

            <Nav />

            <Body>
            <div className="container" style={{paddingTop:'50px'}}>
                <h2>Thank you for subscribing!</h2>

                <p style={{fontSize:"1.5rem"}}>We'll be sending you an update when it's ready. In the meantime, the best way to get involved is to join our online community and start participating. There are plenty of live updates as well as the ability to engage with everyone to help build better houses, learn about automatically growing food, and find out how to get more involved.</p>

                <Button type='success' href='https://discord.gg/yWv3m2WQpP' text='Join Our Discord Community' style={{fontSize:"1.5rem"}} />

                <div style={{height:'50px'}}></div>

            </div>
            </Body>

            <SimpleFooter />
        </div>);
    }
}

export default Research;
