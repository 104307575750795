import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, LogInForm, Header, Div, Container, Span, Link, Navbar, NavLink, Image, Paragraph} from 'library';
import SimpleNavbar from 'pages/custom_project_pages/buildingblocks/simplenavbar.js';
import SimpleFooter from 'pages/custom_project_pages/buildingblocks/simplefooter.js';


class LogIn extends Component {

    render() {

        return (<div>
			<SimpleNavbar />

			<Container order={"7"} style={{'padding-top': '20px'}} min_height={true} >
				<Div className={"row"} >
					<Div className={"col-md-3 col-sm-12"}  />
					<Div className={"col-md-6 col-sm-12"} >
						<Header size={2} text={"Log In"}  />
						<LogInForm redirectUrl={"/dashboard/"}  />
						<Div >
							<Span text={"Forgot your password?"} style={{'padding-right': '10px'}}  />
							<Link href={"/passwordResetRequest/"} text={"Reset Password"}  />
						</Div>
						<Div >
							<Span text={"Not a user?"} style={{'padding-right': '10px'}}  />
							<Link href={"/signUp/"} text={"Sign Up Now"}  />
						</Div>
					</Div>
					<Div className={"col-md-3 col-sm-12"}  />
				</Div>
			</Container>

			<SimpleFooter />
        </div>);
    }
}
export default LogIn;
