import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Container, Button, FormWithChildren, TextArea, TextInput, Alert, StripeMain} from 'library';
import SimpleNavbar from 'pages/custom_project_pages/buildingblocks/simplenavbar.js';
import SimpleFooter from 'pages/custom_project_pages/buildingblocks/simplefooter.js';
import Nav from 'projectLibrary/nav.js';
import Body from 'pages/custom_project_pages/buildingblocks/body.js';

class FirstNeighborhood extends Component {
    constructor(props) {
        super(props);
        this.state = {submitted: false}

        this.submit = this.submit.bind(this);
    }

    submit(state, callback) {
        ajaxWrapper('POST','/api/home/application/', state, callback)
        this.setState({submitted:true})
    }

    render() {

        var submitted = null;
        if (this.state.submitted) {
            submitted = <Alert text='Application Submitted!' type='success' />
        }

        return (<div>

            <Nav />
            <Body>

            <div className="container" style={{paddingTop:'50px'}}>
                <h3>Welcome To The Application Process</h3>

                <p>First, let me say thank you for putting yourself here and showing interest in what we are doing! Thank you.</p>

                <p>The way the application process works is as follows. First, you'll submit the form below. It is pretty short and sweet, but just helps us get a sense of who you are and how we can help each other!</p>

                <p>After that, our staff will take a look at all the applications, follow up with questions we have for you and generally make sure you qualify for one of the two following conditions.</p>

                <ol>
                    <li>You are in need of help. Perhaps you are just scraping by, your currently homeless or hungry or you are at risk of not having adequate shelter, food, water, or clothing for you or your family in the near future.</li>
                    <li>You have the ability to help World From Scratch push forward in its <a href='/about/' target='_blank'>mission</a> and you are willing and able to commit to helping us so long as you live in one of our houses.</li>
                </ol>

                <p>If you do qualify, our staff will then limit it down to those most in need that we can help and those who can really push us forward in our mission. Once our staff has limited down the list, we will give the applications to a panel of at least 3 volunteers that have no familial or business relationships with any of the applicants, who will select the final recipients by a majority vote.</p>

                <p>And then you'll be in and we will set you up to receive a house. I can't wait to hear about you!</p>

                <h3>The Application</h3>
                <FormWithChildren submit={this.submit}>
                    <TextInput name='name' label='Your Full Name' />
                    <TextInput name='email' label='Your Email' />
                    <TextInput name='video_url' label='A 1 Minute Video Introducing Yourself (optional)' />
                    <TextArea name='text' label='Tell Us About Yourself and Your Current Situation' />
                    <TextArea name='good_at' label='What Skills Do You Have? Or Could Contribute to World From Scratch?' />
                </FormWithChildren>
                {submitted}
            </div>
            </Body>

            <SimpleFooter />
        </div>);
    }
}

export default FirstNeighborhood;
