import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';
import {FormWithChildren, TextInput, Alert} from 'library';
import settings from 'base/settings.js';

class Subscribe extends Component {
    constructor(props) {
        super(props);
        this.state = {subscribed:false, error: ''}
        this.subscribe = this.subscribe.bind(this);
    }

    subscribe(state, callback) {
        ajaxWrapper('POST','/api/home/subscriber/', {'email': state['email']}, callback)
        this.setState({subscribed: true})
        window.location.href = '/thankyou/'
    }

    render () {

        var subscribed = null;
        if (this.state.subscribed) {
            subscribed = <Alert text="Thank you for subscribing!" type='success' />
        }

        return (
            <div>
                <FormWithChildren submit={this.subscribe} submit_text={'I want to live in a world where everyone can follow their dreams!'} submitClassName="big_button">
                    <TextInput placeholder='Email' name='email' />
                </FormWithChildren>
                {subscribed}
            </div>

        )
    }

}


export default Subscribe;
