import React, { Component } from 'react';
import {resolveVariables, format_date_string} from 'functions';
import {TextInput, CSSInput, Select} from 'library';

class DateFormat extends React.Component {
    static component_name = 'DateFormat';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'date string'} name={'date'} default={'YYYY-MM-DD HH:MM'} />,
                <TextInput label={'output format'} name={'output_format'} default={'dd-mm-yy'} />,
                <TextInput label={'input format'} name={'input_format'} default={'YYYY-MM-DD HH:MM'} />,
                <Select label={'Only Time'} name={'only_time'} boolean={true} defaultoption={false} />,
                <CSSInput label={'css'} name={'style'} default={{}} />
            ],
        }
    }

    render() {
        var text = '';

        if (this.props.only_time){
            var pieces = this.props.date.split(":");
            var ending = 'AM';
            var hour = parseInt(pieces[0]);
            if (hour > 12){
                hour = hour - 12;
                ending = 'PM';
            }

            text = hour + ':' + pieces[1] + ' ' + ending;

        } else {
            text = format_date_string(this.props.date, this.props.output_format, this.props.timezone);
        }

        return (
            <span style={this.props.style || {}}>{text}</span>
        );
    }
}

export default DateFormat;
