import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Container, Button, Carousel, BackgroundDiv} from 'library';
import SimpleNavbar from 'pages/custom_project_pages/buildingblocks/simplenavbar.js';
import SimpleFooter from 'pages/custom_project_pages/buildingblocks/simplefooter.js';
import Nav from 'projectLibrary/nav.js';
import Body from 'pages/custom_project_pages/buildingblocks/body.js';
import Subscribe from 'projectLibrary/subscribe.js';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";


class Home extends Component {

    render() {
        return (<div className='larger-text'>
            <Nav />
            <Body>
            <BrowserView>
            <div>
                <div class="hero-image">
                    <div class="hero-text">
                        <h3 style={{fontSize:'4rem'}}>Imagine if we built a</h3>
                        <h1 style={{marginLeft:'5%'}}><b style={{fontSize:'4rem'}}>World From Scratch</b></h1>
                        <h3 style={{marginLeft:'10%', fontSize:'4rem'}}>What could it be like?</h3>
                    </div>
                </div>
            </div>
            </BrowserView>
            <MobileView>
            <div>
                <div class="hero-image">
                </div>
                <div style={{textAlign:'center'}}>
                    <h2>Imagine if we built a</h2>
                    <h1><b style={{fontSize:'4rem'}}>World From Scratch</b></h1>
                    <h2>What could it be like?</h2>
                </div>
            </div>
            </MobileView>

            <div>
                <Container>
                <div style={{padding: '40px 0px'}}>
                    <h1>We imagine a world where everyone can follow their dreams.</h1>

                    <br/>
                    <br/>

                    <p style={{fontSize:'1.5rem'}}>To do that we use technology to reduce the cost of living to the point that the phrase “work for a living” has no meaning.  Humankind's achievements in the industrial age have done wonders to enrich the lives of almost everyone, but we think it can do just as much to help simplify our lives as well.  By combining existing technologies, with designs focused on simplicity and ease-of-access, we believe we can evolve our living spaces into far more than simply shelter.  Houses that can generate their own power, grow food for their occupants, produce purified water, and have no utility bills.</p>

                    <p style={{fontSize:'1.5rem'}}>To work on this goal we are building a neighborhood and community of impassioned people from a variety of disciplines.  As we come together and create innovations, each will be shared freely to the world in the hopes of spreading our vision as far and wide as possible.</p>

                    <p style={{fontSize:'1.5rem'}}>In 2020, we built a prototype version of the home. <b style={{fontSize:'1.5rem'}}>It cost under $25,000 to build</b> and includes both a modern design to live in and a semi-automated greenhouse with the space to grow hundreds of plants at once. We think we can do better, with your help. Please add your email below so we can share what we are working on and give you the opportunity to join in with us.</p>

                    <Subscribe />

                    <br/><br/>
                </div>
                </Container>
            </div>
            <div style={{background: '#dde', padding: '60px 0px 40px', position: 'relative'}} >
                <Container>
                    <div style={{width: '100%', textAlign:'center', position: 'absolute', top: '-30px', left: '0px'}}>
                    <div style={{background: '#dde', display: 'inline-block', padding: '15px 25px', borderRadius: '100px'}}>
                        <h4>Check it out!</h4>
                        <i class="fas fa-chevron-down"></i>
                    </div></div>

                    <BrowserView>
                    <div className='row'>
                        <div className='col-5 screenshot'><BackgroundDiv src='https://world-from-scratch.s3.us-east-2.amazonaws.com/IMG_20201013_174240.jpg' /></div>
                        <div className='col-2 screenshot'><BackgroundDiv src='https://world-from-scratch.s3.us-east-2.amazonaws.com/IMG_20201012_103411.jpg' /></div>
                        <div className='col-5 screenshot'><BackgroundDiv src='https://world-from-scratch.s3.us-east-2.amazonaws.com/PXL_20201211_210634274.jpg' /></div>
                        <div className='col-3 screenshot'><BackgroundDiv src='https://world-from-scratch.s3.us-east-2.amazonaws.com/PXL_20201211_211117090.jpg' /></div>
                        <div className='col-3 screenshot'><BackgroundDiv src='https://world-from-scratch.s3.us-east-2.amazonaws.com/PXL_20201211_211201630.jpg' /></div>
                        <div className='col-6 screenshot'><BackgroundDiv src='https://world-from-scratch.s3.us-east-2.amazonaws.com/IMG_20200721_150631.jpg' /></div>
                    </div>
                    </BrowserView>
                    <MobileView>
                    <div className=''>
                        <div className='screenshot'><img style={{maxWidth:'100%'}} src='https://world-from-scratch.s3.us-east-2.amazonaws.com/IMG_20201013_174240.jpg' /></div>
                        <div className='screenshot'><img style={{maxWidth:'100%'}} src='https://world-from-scratch.s3.us-east-2.amazonaws.com/IMG_20201012_103411.jpg' /></div>
                        <div className='screenshot'><img style={{maxWidth:'100%'}} src='https://world-from-scratch.s3.us-east-2.amazonaws.com/PXL_20201211_210634274.jpg' /></div>
                        <div className='screenshot'><img style={{maxWidth:'100%'}} src='https://world-from-scratch.s3.us-east-2.amazonaws.com/PXL_20201211_211117090.jpg' /></div>
                        <div className='screenshot'><img style={{maxWidth:'100%'}} src='https://world-from-scratch.s3.us-east-2.amazonaws.com/PXL_20201211_211201630.jpg' /></div>
                        <div className='screenshot'><img style={{maxWidth:'100%'}} src='https://world-from-scratch.s3.us-east-2.amazonaws.com/IMG_20200721_150631.jpg' /></div>
                    </div>
                    </MobileView>

                    <p style={{color: '#666'}}>Oven/Stove, Refridgerator, and Laundry not pictured.</p>
                </Container>
            </div>

            <div style={{padding: '60px 0px'}}>
                <Container>
                    <h2>What is our next goal?</h2>
                    <p style={{fontSize:'1.5rem'}}>
                        In 2021, we are planning on starting the first neighborhood of homes. We will be starting with a modular house design. So, that you could start off with a studio appartment and build your way up to the family home of your dreams. To do that, we are looking for people that are either committed to helping create a better world with us, or someone who is in need and a house like ours would profoundly change their life.
                    </p>

                    <h2>How can you get involved?</h2>
                    <p style={{fontSize:'1.5rem'}}>The first step is to sign up to our monthly update. We'll then send you a link to our community channel where you can jump in and participate. From helping to design the next generation of houses or figure out how to automatically grow the world's food. Or you can just get in there and talk to us about how to get plans for your own house or greenhouse and we can get you set up. This is a team effort and we're excited to get to know you and help you follow your dreams!</p>

                    <br />
                    <br />
                    <p>Sign Up To Get Monthly Updates on Our Progress</p>
                    <Subscribe />

                </Container>
            </div>


            </Body>

            <SimpleFooter subscribe_off={true} />
        </div>);
    }
}

export default Home;
