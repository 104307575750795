import React, { Component } from 'react';
import {ajaxWrapper} from 'functions';
import settings from 'base/settings.js';
import {Navbar, NumberInput} from 'library';

class Nav extends React.Component {
    constructor(props) {
        super(props);
        this.config = {
            form_components: [

            ],
            can_have_children: true,
        }
    }

    render() {
        var name = <div><img style={{'marginRight':'10px'}} src='https://world-from-scratch.s3.us-east-2.amazonaws.com/fire_heart.jpg' height="125" /></div>;

        var links = [['/research/','Public Research'],['/apply/','Apply'], ['/donate/','Donate'], ['/contact/','Contact Us']];

        var linkHTML = [];
        for (var index in links) {
          linkHTML.push(<li className="nav-item">
             <a className="nav-link" data-value="about" href={links[index][0]}>{links[index][1]}</a>
          </li>)
        }

        return (
          <nav className="navbar navbar-expand-lg navbar-light" style={{padding:'10px'}}>
            <a className="navbar-brand" href="/" style={{paddingLeft:'10px',paddingRight:'10px'}}>World From Scratch</a>

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse " id="navbarSupportedContent">
              <ul className="navbar-nav ml-4">
                {linkHTML}
              </ul>
            </div>
          </nav>
        );
    }
}


export default Nav;
