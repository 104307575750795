import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {NumberInput, TextInput, CSSInput, AddChildComponent} from 'library';

class BackgroundDiv extends React.Component {
    static component_name = 'Div';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'class'} name={'className'} default={''} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
                <TextInput label={'Image'} name={'src'} default={''} />,
            ],
            can_have_children: false,
        }
    }

    render() {
        var style = Object.assign({
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }, this.props.style);

        style['backgroundImage'] = 'url("' + this.props.src + '")';

        return (
            <div className={this.props.className || ''} style={style}></div>
        );
    }
}


export default BackgroundDiv;
