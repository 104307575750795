import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, Div, Image, Span, Link, Paragraph, FormWithChildren} from 'library';
import Subscribe from 'projectLibrary/subscribe.js';

class SimpleFooter extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        var subscribe = [<h5>Sign Up To Get Monthly Updates on Our Progress</h5>, <Subscribe />]


        if (this.props.subscribe_off) {
            subscribe = null;
        }

        return (<div>
			<Div className={'footer-wrapper'} >
				<Div style={{'': ''}} className={"row"} >
					<Div style={{'textAlign': 'left'}} className={"col-md-4 col-sm-12"} >
                        {/* Left-Hand Side */}
                        {subscribe}
					</Div>
					<Div className={"col-md-8 col-sm-12"} >
                        <div className='footer-links'>
                            <a href='/contact/'>Contact Us</a>
                            <a href='/donate/'>Donate</a>
                            <a href='/apply/'>Apply</a>
                            <a href='/research/'>Public Research</a>
                            <a href='https://discord.gg/yWv3m2WQpP'>Join Our Discord Community</a>
                        </div>
                        <div style={{textAlign: 'right', marginTop: '40px'}} ><p>COPYRIGHT 2020  |  ALL RIGHTS RESERVED</p></div>
					</Div>
				</Div>
			</Div>
        </div>);
    }
}
export default SimpleFooter;
