import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, Navbar, NavLink, If} from 'library';

class SimpleNavbar extends Component {

    render() {

        return (<div>
			<If anti_logic={[['exists', '{user.id}']]} >
				<Navbar name={"Simple Navbar"} style={{'backgroundColor': 'white'}} name_link={"/"} >
					<NavLink href={"/"} name={"HOME"} />
					<NavLink href={"/about/"} name={"ABOUT"} />
					<NavLink href={"/contact/"} name={"CONTACT"} />
					<NavLink href={"/login/"} name={"LOG IN"} />
				</Navbar>
			</If>

			<If logic={[['exists', '{user.id}'], ['{user.is_staff}', 'false']]} override={false} >
				<Navbar name={"Simple Navbar"} style={{'backgroundColor': 'white'}} name_link={"/"} >
					<NavLink href={"/dashboard/"} name={"HOME"} />
					<NavLink href={"/about/"} name={"ABOUT"} />
					<NavLink href={"/contact/"} name={"CONTACT"} />
					<NavLink href={"/logout/"} name={"LOG OUT"} />
				</Navbar>
			</If>

			<If logic={[['exists', '{user.id}'], ['{user.is_staff}', 'true']]} >
				<Navbar name={"Simple Navbar"}  style={{'backgroundColor': 'white'}} name_link={"/"} >
					<NavLink href={"/dashboard/"} name={"HOME"} />
					<NavLink href={"/users/"} name={"USERS"} />
					<NavLink href={"/about/"} name={"ABOUT"} />
					<NavLink href={"/contact/"} name={"CONTACT"} />
					<NavLink href={"/logout/"} name={"LOG OUT"} />
				</Navbar>
			</If>
        </div>);
    }
}
export default SimpleNavbar;
