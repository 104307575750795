import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, Container, Header, FormWithChildren, PasswordInput, Navbar, NavLink, If, Div, Image, Span, Link, Paragraph} from 'library';
import SimpleNavbar from 'pages/custom_project_pages/buildingblocks/simplenavbar.js';
import SimpleFooter from 'pages/custom_project_pages/buildingblocks/simplefooter.js';


class PasswordReset extends Component {

    render() {

        return (<div>
			<SimpleNavbar />

			<Container order={"1"} style={{'padding-top': '20px'}} min_height={true} >
				<Header text={"Reset Your Password"}  />
				<FormWithChildren submitUrl={resolveVariables({"text":"/api/user/user/{params.1}/"}, window.cmState.getGlobalState(this))["text"]} redirectUrl={"/login/"} >
					<PasswordInput name={"password"} required={true} confirm_password={true}  />
				</FormWithChildren>
			</Container>

			<SimpleFooter />
        </div>);
    }
}
export default PasswordReset;
