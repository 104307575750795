import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, PasswordResetRequest, Container, TextInput, Navbar, NavLink, Div, Image, Span, Link, Paragraph} from 'library';
import SimpleNavbar from 'pages/custom_project_pages/buildingblocks/simplenavbar.js';
import SimpleFooter from 'pages/custom_project_pages/buildingblocks/simplefooter.js';


class PasswordResetRequestPage extends Component {

    render() {

        return (<div>
			<SimpleNavbar />

			<Container order={"3"} style={{'padding-top': '20px'}} min_height={true} >
				<PasswordResetRequest >
					<TextInput name={"email"} required={true} placeholder={"your_email@domain.com"}  />
				</PasswordResetRequest>
			</Container>

			<SimpleFooter />
        </div>);
    }
}
export default PasswordResetRequestPage;
