// index.js
import React from 'react';
import {StripeProvider} from 'react-stripe-elements';
import {TextInput, Function_Input} from 'library';
import StripeElements from './stripeelements.js';

class StripeMain extends React.Component {
    static component_name = 'StripeMain';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'api key'} name={'apiKey'} default={null} />,
                <TextInput label={'Submit Text'} name={'submit'}  />,
                <TextInput label={'Email'} name={'email'} />,
                <TextInput label={'Credit Card Charge Description'} name={'description'} />,
                <TextInput label={'Price'} name={'price'} />,
                <TextInput label={'URL'} name={'url'} />,
                <Function_Input label={'Add Function'} default={''} name={'functions'} />,
            ],
        }
    }

    render() {
        var api_key = this.props.api_key;

        return (
            <StripeProvider apiKey={api_key} >
            <StripeElements {...this.props} />
            </StripeProvider>
        );
    }
}


export default StripeMain;
