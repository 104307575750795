import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Container, Button, FormWithChildren, TextArea, TextInput, Alert, StripeMain} from 'library';
import SimpleNavbar from 'pages/custom_project_pages/buildingblocks/simplenavbar.js';
import SimpleFooter from 'pages/custom_project_pages/buildingblocks/simplefooter.js';
import Nav from 'projectLibrary/nav.js';
import Body from 'pages/custom_project_pages/buildingblocks/body.js';

class FirstNeighborhood extends Component {

    render() {


        return (<div>

            <Nav />
            <Body>
            <div className="container" style={{paddingTop:'50px'}}>
                <h3>The First Neighborhood</h3>

                <p>We built the prototype house with our own hands and funded it with our own money. We knew there was plenty of opportunity to save money and understand exactly how we could make a house that takes care of its resident by building it ourselves. And let me tell you, while it was hard work, it was well worth it. We've found that with some guidance from experts, anyone can build their own house for way cheaper than you may have ever imagined.</p>

                <p>However, that was only step one. It was important to us to know that we could change the world before asking for money from you. We didn't want to risk your hard earned money on a chance. We wanted to make sure that we were ready. And now we are, so let's build the real thing together.</p>

                <p>This first neighborhood is all about scaling the idea and improving our methods so that it can be replicated all around the world and help people start living their lives without worrying about money.</p>

                <p>So, with that we intend to build out 8-10 homes that will then be leased out to those in need or those who can push our goal forward with access to a house and greenhouse. That might be experts in construction, growing plants, leading volunteers, leading communities, etc. Along the way we will publish everything from the building plans that you could then commission to build on your own, to the software that runs the greenhouse so you could turn your own backyard into a automated farm, to the ways that we change the people's lives that join us.</p>

                <p>But this time, we can't do it alone. We need your help. Please donate, join our community to push us forward, or apply for a house yourself.</p>

                <h3>Help Us Move The Mission Forward With Your Ideas!</h3>
                <p>We have an online community with people just like you helping us and themselves push the world forward. Whether you are interested in giving us feedback on the website, you've got great taste in interior design, you've got a green thumb, just want to encourage us or you've got any other ways to help, please join us.</p>
                <Button type='dark' href='https://discord.gg/yWv3m2WQpP' text='Join Our Discord Community' />

                <h3 style={{marginTop:'20px'}}>Help Us Fund The First Neighborhood</h3>
                <p>To get the first neighborhood started, we need some help acquiring land and purchasing the building materials for the first neighborhood. And literally every dollar counts toward making this happen. So, please donate to the cause, let's get that first neighborhood built and give everyone the ability to follow their dreams.</p>
                <Button type='success' href='/donate/' text='Donate' />

                <h3 style={{marginTop:'20px'}}>Apply To Live In Our First Neighborhood</h3>
                <p>Our first neighborhood isn't going to be a bunch of empty houses. It will be full of people from all sorts of backgrounds, ready to radically change the way we look at housing. And I bet you could be one of them!</p>
                <Button type='primary' text='Apply' />


            </div>
            </Body>
            <SimpleFooter />
        </div>);
    }
}

export default FirstNeighborhood;
