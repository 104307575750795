import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Container, Button, FormWithChildren, TextArea, TextInput, Alert, StripeMain} from 'library';
import SimpleNavbar from 'pages/custom_project_pages/buildingblocks/simplenavbar.js';
import SimpleFooter from 'pages/custom_project_pages/buildingblocks/simplefooter.js';
import Nav from 'projectLibrary/nav.js';
import Body from 'pages/custom_project_pages/buildingblocks/body.js';

class Contact extends Component {


    render() {


        return (<div>

            <Nav />
            <Body>
            <div className="container" style={{paddingTop:'50px'}}>
                <h3>About World From Scratch</h3>

                <p>We believe that once people's basic needs are met without having to sacrifice, they will be able to finally be able to follow their dreams, wherever they may take them.</p>

                <p>Therefore, It is the mission, duty and purpose of World From Scratch to provide housing and technology that meets and supplements the basic needs of every person around the world. WFS will develop, fund and publicly share homes and technologies that make housing more affordable and energy efficient, benefit the environment, allow for more sustainable growing of food, contribute to ongoing research and education, and ensure that everyone's basic needs are met.</p>
            </div>
            </Body>

            <SimpleFooter />
        </div>);
    }
}

export default Contact;
