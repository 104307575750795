import 'babel-polyfill';
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11';

import React, { Component } from 'react';
import './App.css';
import {ajaxWrapper, setGlobalState, clear_tokens} from "functions";
import {Wrapper} from 'library';
import * as Sentry from '@sentry/browser';

//Admin
import AppList from './pages/admin/appList.js';
import ModelList from './pages/admin/modelsList.js';
import ModelMaker from './pages/model_builder/djangoModelMaker.js';
import InstanceList from './pages/admin/modelInstances.js';
import Instance from './pages/admin/instance.js';
import InstanceTable from './pages/admin/modelInstancesTable.js';

import LogIn from './pages/custom_project_pages/usermanagement/login.js';
import SignUpPage from './pages/custom_project_pages/usermanagement/signupform.js';
import PasswordReset from './pages/custom_project_pages/usermanagement/passwordreset.js';
import PasswordResetRequestPage from './pages/custom_project_pages/usermanagement/passwordresetrequest.js';
import ContactUs from './projectLibrary/contact_us.js';

import UserList from './pages/custom_project_pages/usermanagement/userlist.js';
import EditUser from './pages/custom_project_pages/usermanagement/edituser.js';
import AdminEditUser from './pages/custom_project_pages/usermanagement/adminedituser.js';

import Viewfilegroup from './pages/custom_project_pages/startingpages/viewfilegroup.js';
import FileManager from './pages/custom_project_pages/startingpages/filemanager.js';
import CSSClasses from './pages/custom_project_pages/startingpages/cssclasses.js';
import EditCSSClass from './pages/custom_project_pages/startingpages/editcssclass.js';

import Nav from 'projectLibrary/nav.js';
//import Footer from 'projectLibrary/footer.js';

//API Querying
import APIDocs from './pages/admin/apiDocs.js';

import Home from './projectLibrary/home.js';
import Donate from './projectLibrary/donate.js';
import About from './projectLibrary/about.js';
import Research from './projectLibrary/research.js';
import FirstNeighborhood from './projectLibrary/first_neighborhood.js';
import Apply from './projectLibrary/apply.js';
import ThankYou from './projectLibrary/thankyou.js'

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            csrfmiddlewaretoken: undefined,
            user:{}
        };

        this.load_global_context = this.load_global_context.bind(this);
        this.load_user = this.load_user.bind(this);
    }

    componentDidMount() {
        this.load_global_context(window.secretReactVars.global_context);

        var path = this.getURL()[0].toLowerCase();

        var token = localStorage.getItem('token');

        var loginNoRedirects = ['login','signup','passwordresetrequest', 'passwordreset', 'admin','about','contact','research', 'donate', 'first_neighborhood', 'apply', 'thankyou'];

        if (token) {
            //ajaxWrapper("GET", "/users/user/", {}, this.load_user.bind(this), clear_tokens);
            this.load_user(window.secretReactVars.logged_in_user);

            this.setState({token: token});
            if (path.indexOf('login') > -1) {
                window.location.href = '/dashboard/';
            }
        } else if (loginNoRedirects.indexOf(path) == -1 && window.location.pathname != "/") {
            window.location.href = '/login/';
        }
        else {
          this.setState({'loaded':true})
        }
    }

    load_user(result){
        if (!(result)){
            clear_tokens();
        }
        else {
            console.log("Load User Result", result)
            window.cmState.setGlobalState('user',result);
            this.setState({
                user: result,
                loaded: true,
            });
        }
    }

    logOut() {
        console.log("Log Out");
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('token_time');
        window.location.href = '/users/logout/';
    }

    load_global_context(value) {
        window.secretReactVars["csrfmiddlewaretoken"] = value['csrfmiddlewaretoken'];
        window.secretReactVars["twofactor"] = value['twofactor'];
        window.secretReactVars["secure_uploads"] = value['secure_uploads'];

        this.setState({
            csrfmiddlewaretoken: value.csrfmiddlewaretoken,
            twofactor: value['twofactor'],
            secure_uploads: value['secure_uploads']
        });
    }


    getURL() {
        var url = window.location.pathname;
        if (url[0] == '/'){ url = url.substring(1);}
        if (url[url.length - 1] == '/'){ url = url.substring(0,url.length-1);}
        var url_split = url.split('/');

        var params = {};
        for (var index in url_split) {
            params[index] = url_split[index]
        }

        var href = window.location.href;
        if (href.indexOf('?') > -1) {
            var post_params = href.split('?')[1];
            var split_params = post_params.split('&');

            for (var index in split_params) {
                var temp = split_params[index]
                var temp_split = temp.split('=')
                params[temp_split[0]] = temp_split[1]
            }
        }

        return params;
    }

    render() {
        var params = this.getURL();
        var param_dict = {};
        for (var index in params) {
            param_dict[index.toString()] = params[index]
        }
        window.cmState.setGlobalState('params',param_dict)
        window.cmState.setGlobalState('location', window.location)

        var adminPages = [
            'applist','models','modelinstances',
            'modelinstancestable','instance',
        ];

        var loggedInPages = [];
        var route = params[0].toLowerCase();

        var loading = <h1>Loading . . . </h1>;
        var content = null;
        var navbar = null;

        if (this.state.loaded) {
            var navbar = <Nav user_id={this.state.user.id} is_staff={this.state.user.is_staff} logOut={this.logOut} />;

            if (adminPages.indexOf(route) > -1 && this.state.loaded && !(this.state.user.is_staff)){
                //window.location = '/';
                console.log("Not an admin", this.state.loaded, this.state.user)
            } else if (loggedInPages.indexOf(route) > -1 && this.state.loaded && typeof(this.state.user.id) != 'undefined'){
                //window.location = '/login/';
                console.log("Need to be logged in");
            }
            else if (route == 'logout') {
                this.logOut();
            }
            else if (route == "apply") {
    			content = <Apply />;
    		}
            else if (route == "first_neighborhood") {
    			content = <FirstNeighborhood />;
    		}
            else if (route == "thankyou") {
    			content = <ThankYou />;
    		}
            else if (route == "research") {
    			content = <Research />;
    		}
            else if (route == "donate") {
    			content = <Donate />;
    		}
            else if (route == "about") {
    			content = <About />;
    		}
            else if (route == "login") {
    			content = <LogIn />;
    		}
    		else if (route == "signup") {
    			content = <SignUpPage />;
    		}
            else if (route == "passwordreset") {
    			content = <PasswordReset />;
    		}
    		else if (route == "passwordresetrequest") {
    			content = <PasswordResetRequestPage />;
    		}
            else if (route == "contact") {
                content = <ContactUs />;
    		}

      		else if (route == "users") {
      			content = <UserList />;
      		}
              else if (route == "edituser") {
      			content = <EditUser />;
      		}
              else if (route == "adminedituser") {
      			content = <AdminEditUser />;
      		}

      		else if (route == "filegroup") {
      			content = <Viewfilegroup id={params[1]} />;
      		}
              else if (route == "filemanager") {
      			content = <FileManager />;
      		}
              else if (route == "css") {
      			content = <CSSClasses />;
      		}
      		else if (route == "css_class") {
      			content = <EditCSSClass />;
      		}

            else if (route == "applist") {
                content = <AppList user_id={this.state.token}/>;
            }
            else if (route == "models") {
                content = <ModelList app={params[1]} user_id={this.state.token}/>;
            }
            else if (route == "modelinstances") {
                content = <InstanceList app={params[1]} model={params[2]} user_id={this.state.token}/>;
            }
            else if (route == "modelinstancestable") {
                content = <InstanceTable app={params[1]} model={params[2]}/>;
            }
            else if (route == "modelmaker") {
                content = <ModelMaker user_id={this.state.token}/>;
            }

            else if (route == "instance") {
                content = <Instance app={params[1]} model={params[2]} id={params[3]} user_id={this.state.token}/>;
            }
            else if (route == 'apidocs') {
                content = <APIDocs />;
            }

            else if (route == '') {
                content = <Home />;
            }
        }

        if (this.state.loaded == false) {
            return (
                <div className="App">
                    <Wrapper content={<div></div>} loaded={this.state.loaded} />
                </div>
            );
        }
        else {
            return (
                <div className="App">
                    <Wrapper style={{paddingTop: '60px'}} content={content} loaded={this.state.loaded} />
                </div>
            );
        }
    }
}

export default App;
