import React, { Component } from 'react';
import {resolveVariables, run_functions} from 'functions';
import {TextInput, Select, Function_Input, DisplaySize} from 'library';

class Modal extends Component {
    static component_name = 'EmptyModal';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'class'} name={'className'} default={''} />,
                <Select label={'show'} name={'show'} boolean={true} />,
                <Function_Input label={'onHide'} name={'functions'} />,
            ],
            can_have_children: true,
        }

        this.onHide = this.onHide.bind(this);
    }

    onHide() {
        console.log("ON HIDE!!")
        if (this.props.functions) {
          run_functions(this.props.functions)
        }
        else if (this.props.onHide) {
          this.props.onHide();
        }
    }

    render() {
        var backgroundStyle = {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0px',
            left: '0px',
            background: 'rgba(0,0,0,0.2)',
            zIndex: '1',
            margin: 'auto'
        };

        var height = window.outerHeight * .85;

        var modalStyle = {};
        var modalClass = "modal fade";
        if (this.props.show == true) {
            modalClass += " show";
            modalStyle.display = "block";
        }

        return (
            <div className={modalClass} tabindex="-1" role="dialog" style={modalStyle} >
                <div onClick={this.onHide} style={backgroundStyle}></div>
                <DisplaySize display_type={['browser','tablet']}>
                    <div className="modal-dialog" role="document" style={{zIndex: '10', maxWidth:'2000px', marginTop:'3%', display:'flex', justifyContent:'center'}}>
                        <div className="modal-content" style={{minWidth:'50%', maxWidth:'100%', maxHeight:height, overflow:"auto"}}>
                            {this.props.children}
                        </div>
                    </div>
                </DisplaySize>
                <DisplaySize display_type={['mobile']}>
                    <div className="modal-dialog" role="document" style={{zIndex: '10', maxWidth:'100%', marginTop:'5%', display:'flex', justifyContent:'center'}}>
                        <div className="modal-content" style={{minWidth:'50%', maxWidth:'100%', maxHeight:height, overflow:"auto"}}>
                            {this.props.children}
                        </div>
                    </div>
                </DisplaySize>
            </div>
        );
    }
}

export default Modal;
